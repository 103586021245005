/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import UserContact from '../komponents/UserContact/UserContact';
import LoginComponent from '../komponents/LoginComponent/LoginComponent';
import useAuth from '../hooks/useAuth';

const UsarioContacto: FunctionComponent = () => {
  const { state, isAuthenticated } = useAuth();

  return (
    <>
      <Header />
      {isAuthenticated ? <UserContact /> : <LoginComponent />}
      <Footer />
    </>
  );
};

export default UsarioContacto;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="UserPage contact details PuntoTerminal" />
    <title>Edita datos de contacto - PuntoTerminal.mx</title>
  </>
);
