import React, { FunctionComponent } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import * as yup from 'yup';
import { Link } from 'gatsby';
import {
  pad,
  errorMessage,
  sameRowFormInput,
  formDiv,
} from './UserContact.module.scss';
import useAuth from '../../hooks/useAuth';
import useLanguageStore from '../LanguageStore/LanguageStore';

const contactSchema = yup.object().shape({
  personFirstname: yup.string().required('Please enter a first name.'),
  personLastname: yup.string().required('Please enter a second name.'),
  CompanyName: yup.string().required('Please enter a company name.'),
  // CompanyNumber: yup.number(),
  Email: yup.string().email().required('Please enter an email.'),
  Phone: yup.number(),
  // Yup.string()
  // .matches(phoneRegExp, 'Phone number is not valid.')
  // .required('Please enter a phone number.')
});

type LanguageContent = {
  leftHeroHeader: string;
  leftHeroBody: string;
  // form
  login: string;
  loginLink: string;
  personFirstname: string;
  personLastname: string;
  CompanyName: string;
  CompanyNumber: string;
  Email: string;
  Phone: string;
  Password: string;
  ConfirmPW: string;
  termsText: string;
  termsLink: string;
  privacyLink: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  leftHeroHeader: 'Your quotes are ready!',
  leftHeroBody: 'We need a way to contact you. Please create an account.',
  // Form
  login: 'Already registered? Click ',
  loginLink: 'here to login.',
  personFirstname: 'First name',
  personLastname: 'Last name',
  CompanyName: 'Company Name',
  CompanyNumber: 'Company Registration Number',
  Email: 'Email',
  Phone: 'Phone',
  Password: 'Password',
  ConfirmPW: 'Confirm Password',
  termsText: 'By signing up, you agree to our',
  termsLink: ' Terms,',
  privacyLink: ' Data Policy and Cookies.',
};
content.Español = {
  leftHeroHeader: 'Tus ofteras estan listas',
  leftHeroBody: 'Para ver las ofertas, necesitas una cuenta con PuntoTerminal.',
  // Form
  login: 'Tienes una cuenta? Click ',
  loginLink: 'aqui para logear.',
  personFirstname: 'Nombre',
  personLastname: 'Apellidos',
  CompanyName: 'Nombre de la empresa',
  CompanyNumber: 'RFC CURP',
  Email: 'Correo electrónico',
  Phone: 'Teléfono',
  Password: 'Contraseña',
  ConfirmPW: 'Confirmar Contraseña',
  termsText: 'Con el acto de crear el usario, acepta nuestros ',
  termsLink: ' Términos y condiciones,',
  privacyLink: ' Política y Cookies.',
};

const UserContact: FunctionComponent = () => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     // Contact Form
  //     personFirstname: '',
  //     personLastname: '',
  //     CompanyName: '',
  //     // CompanyNumber: '',
  //     Email: '',
  //     Phone: '',

  //     errors_personFirstname: false,
  //     errors_personLastname: false,
  //     errors_CompanyName: false,
  //     // errors_CompanyNumber: false,
  //     errors_Email: false,
  //     errors_Phone: false,
  //   };
  // }

  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;

  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  // handleSubmit = () => {
  //   const contactData = {
  //     personFirstname: this.state.personFirstname,
  //     personLastname: this.state.personLastname,
  //     CompanyName: this.state.CompanyName,
  //     CompanyNumber: this.state.CompanyNumber,
  //     Email: this.state.Email,
  //     Phone: this.state.Phone,
  //   };
  //   this.validateSchema(contactSchema, contactData, alert('Form submitted'));
  // };

  // async validateSchema(schema: any, data: any, nextStep: any) {
  //   const currentErrors = [];

  //   const validationResult = await schema
  //     .validate(data, { abortEarly: false })
  //     .then(() => {
  //       // Form is valid, move to next step.
  //       nextStep();
  //     })
  //     .catch((err) => {
  //       err.inner.forEach((e) => {
  //         // console.log(e.message, e.path);

  //         currentErrors.push(e.path);
  //         const stateName = `errors_${e.path}`;
  //         this.setState({
  //           [stateName]: true,
  //         });
  //       });
  //     });

  //   // Iterate over data and reset errors if no longer an error.
  //   for (const key in data) {
  //     if (!currentErrors.includes(key)) {
  //       const stateName = `errors_${key}`;
  //       this.setState({
  //         [stateName]: false,
  //       });
  //     }
  //   }
  // }

  const { logout, isAuthenticated } = useAuth();

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <>
      <Container className="d-flex justify" id="wrapper">
        <div className="border-end bg-white" id="sidebar-wrapper">
          <div className="list-group list-group-flush">
            <Link to="/">
              <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                Home
              </Button>
            </Link>

            <Link to="/usario-contacto">
              <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                Datos de Contacto
              </Button>
            </Link>

            <Link to="/cambia-contrasena">
              <Button className="list-group-item list-group-item-action list-group-item-light p-3">
                Cambiar contraseña
              </Button>
            </Link>

            <Link to="/">
              <Button
                className="list-group-item list-group-item-action list-group-item-light p-3"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Link>
          </div>
        </div>
        <div className={formDiv}>
          <div className={pad} />

          <div>
            <h1>Editar Datos de Contacto</h1>

            {/* <Form>
                <Row>
                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        {' '}
                        {this.choosenLanguage.personFirstname}{' '}
                      </Form.Label>
                      <Form.Control
                        className={sameRowFormInput}
                        type="text"
                        placeholder=""
                        name="personFirstname"
                        value={this.state.personFirstname}
                        onChange={this.state.handleChange}
                      />

                      {this.state.errors_personFirstname && (
                        <p className={errorMessage}>Por favor pon su nombre.</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={6} lg={6}>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>
                        {' '}
                        {this.choosenLanguage.personLastname}{' '}
                      </Form.Label>
                      <Form.Control
                        className={sameRowFormInput}
                        type="text"
                        placeholder=""
                        name="personLastname"
                        value={this.state.personLastname}
                        onChange={this.state.handleChange}
                      />

                      {this.state.errors_personLastname && (
                        <p className={errorMessage}>
                          Por favor pon sus appelidos.
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label> {this.choosenLanguage.CompanyName} </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="CompanyName"
                    value={this.state.CompanyName}
                    onChange={this.state.handleChange}
                  />

                  {this.state.errors_CompanyName && (
                    <p className={errorMessage}>
                      Por favor pon el nombre de su empresa.
                    </p>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label> {this.choosenLanguage.Email} </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="Email"
                    value={this.state.Email}
                    onChange={this.state.handleChange}
                  />

                  {this.state.errors_Email && (
                    <p className={errorMessage}>Por favor pon su correo.</p>
                  )}
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label> {this.choosenLanguage.Phone} </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="Phone"
                    value={this.state.Phone}
                    onChange={this.state.handleChange}
                  />

                  {this.state.errors_Phone && (
                    <p className={errorMessage}>
                      Por favor pon su numero telefonico.
                    </p>
                  )}
                </Form.Group>

                <Button
                  variant="success"
                  size="lg"
                  onClick={() => this.handleSubmit()}
                >
                  <div>Submit</div>
                </Button>
              </Form> */}
          </div>
          <div className={pad} />
        </div>
      </Container>
    </>
  );
};

export default UserContact;
